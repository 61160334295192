import React, { useCallback, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { differenceInDays } from 'date-fns';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/Layout';
import Select from '../components/Select';
import { Scroll } from '../components/Animation';
import SEO from '../components/seo';
import BlogSvg from '../images/q/q-blog.svg';
import styles from './styles/blog.module.scss';
import { formatDateBlog, scrollToId } from '../common/utils';
import useContentfulBlogs from '../hooks/useContentfulBlogs';
import ContentfulLinks from '../components/ContentfulLinks';
import { INLINES } from '@contentful/rich-text-types';

const LIMIT = 6;

const POST_CONTAINER_ID = 'postContainerV3';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ContentfulLinks,
  },
};

const filterSortBlogs = (blogs, filter, sort) => {
  const sorted = blogs.sort(({ node: left }, { node: right }) => {
    const leftDate = new Date(left.date);
    const rightDate = new Date(right.date);
    if (sort === NEWEST) {
      return differenceInDays(rightDate, leftDate);
    }
    return differenceInDays(leftDate, rightDate);
  });
  return sorted.filter(
    ({ node }) => filter === VIEW_ALL || node.category === filter,
  );
};

const Item = ({
  title,
  subtitle,
  slug,
  date: postDate,
  photo,
  alt,
  className,
  description,
  readTime,
}) => {
  const blogUrl = `/blog/${slug}`;
  const date = formatDateBlog(postDate);
  const fullTitle = subtitle ? `${title} ${subtitle}` : title;

  const desc =
    description && description.content
      ? {
          ...description,
          content: description.content.filter(
            (c) => c.nodeType === 'paragraph',
          ),
        }
      : description;

  return (
    <Link
      className={classnames(styles.item, className)}
      to={blogUrl}
      title={fullTitle}
      rel="bookmark"
    >
      <img src={photo} alt={alt} />
      <div className={styles.itemContent}>
        <div className={styles.itemTitle} title={fullTitle}>
          {fullTitle}
        </div>
        <time className={styles.itemDate} date={date}>
          <span>{date}</span>
          {!!readTime && (
            <span className={styles.readTime}>{`${readTime} min read`}</span>
          )}
        </time>
        {description && (
          <>
            <div className={styles.description}>
              {documentToReactComponents(desc, options)[0]}
            </div>
            <div className={styles.readMore}>
              <span>Read more</span>
              <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

const VIEW_ALL = 'View all';
const NEWEST = 'Newest';

const FILTER_OPTIONS = [
  { value: VIEW_ALL, label: VIEW_ALL },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Financial', label: 'Financial' },
  { value: 'Lifestyle', label: 'Lifestyle' },
];

const SORT_OPTIONS = [
  { value: NEWEST, label: NEWEST },
  { value: 'Oldest', label: 'Oldest' },
];

const Blog = () => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(VIEW_ALL);
  const [sort, setSort] = useState(SORT_OPTIONS[0].value);
  const blogs = useContentfulBlogs();

  const filteredItems = useMemo(() => {
    return filterSortBlogs(blogs, filter, sort);
  }, [blogs, filter, sort]);

  const items = useMemo(() => {
    const offset = page === 0 ? 1 : 0;
    const position = page * LIMIT + offset;
    return filteredItems.slice(position, position + LIMIT);
  }, [filteredItems, page]);

  const featured = filteredItems[0];

  const handlePageChange = useCallback(({ selected }) => {
    setPage(selected);
    scrollToId(POST_CONTAINER_ID);
  }, []);

  const maxPage = Math.ceil(filteredItems.length / LIMIT);

  const handleFilterSelect = useCallback(({ value }) => {
    setFilter(value);
  }, []);

  const handleSortSelect = useCallback(({ value }) => {
    setSort(value);
  }, []);

  return (
    <Layout footerClassName={styles.footer}>
      <SEO title="Articles" />
      <div className={styles.header}>
        <div className={classnames('container', styles.title)}>
          <span>The Quashed articles</span>
          <BlogSvg />
        </div>
      </div>
      <div className={classnames('container', styles.container)}>
        <div className={styles.filterContainer}>
          <div className={styles.headerFilters}>
            {FILTER_OPTIONS.map(({ value, label }) => (
              <div
                key={value}
                onClick={() => setFilter(value)}
                className={classnames(
                  styles.filter,
                  filter === value && styles.active,
                )}
              >
                {label}
              </div>
            ))}
            <Select
              className={styles.filterSelect}
              options={FILTER_OPTIONS}
              defaultValue={FILTER_OPTIONS[0]}
              onChange={handleFilterSelect}
            />
          </div>
          <div className={styles.sort}>
            <span>Sort by</span>
            <Select
              className={styles.sortSelect}
              options={SORT_OPTIONS}
              defaultValue={SORT_OPTIONS[0]}
              onChange={handleSortSelect}
            />
          </div>
        </div>
        {featured && (
          <div className={styles.featured}>
            <div className={styles.featuredTitle}>Featured articles</div>
            <Item
              className={styles.featuredItem}
              key={featured.node.slug}
              {...featured.node}
              photo={featured.node.photo.fluid.src}
              alt={featured.node.photo.title}
              description={featured.node.blurb || featured.node.richText.json}
            />
          </div>
        )}
        <Scroll id={POST_CONTAINER_ID} className={styles.items}>
          {items.map(({ node }) => {
            return (
              <Item
                key={node.slug}
                {...node}
                photo={node.photo.fluid.src}
                alt={node.photo.title}
              />
            );
          })}
          <div className={styles.pagerContainer}>
            {page > 0 && (
              <div
                className={styles.previous}
                onClick={() => handlePageChange({ selected: page - 1 })}
              >
                <i className="fa-solid fa-chevron-left" aria-hidden="true" />
                <span>{sort === NEWEST ? 'Newer' : 'Older'}</span>
              </div>
            )}
            <ReactPaginate
              breakLabel={'...'}
              pageCount={maxPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={classnames('', styles.pager)}
              previousClassName={styles.hide}
              nextClassName={styles.hide}
              activeClassName={styles.active}
              forcePage={page}
            />
            {page < maxPage - 1 && (
              <div
                className={styles.next}
                onClick={() => handlePageChange({ selected: page + 1 })}
              >
                <span>{sort === NEWEST ? 'Older' : 'Newer'}</span>
                <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </div>
            )}
          </div>
        </Scroll>
      </div>
    </Layout>
  );
};

export default Blog;
